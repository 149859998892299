import React, { useState } from 'react';
import { useParams, useLocation } from '@thd-olt-component-react/router';
import { Row, Col } from '@thd-olt-component-react/grid';
import { ALPRedirector } from '@thd-nucleus/app-render';
import {
  ArticleTilesContainer,
  DiyBanner,
  GetItInstalled,
} from '@thd-olt-component-react/article-results';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { ArticleListingTopics } from '@thd-olt-component-react/article-listing-topics';
import { ArticleListingFilters } from '@thd-olt-component-react/article-listing-filters';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { Pagination } from '@thd-olt-component-react/pagination';
import { ArticleListingMetadata } from '@thd-olt-component-react/metadata';
import { SponsoredNonEndemic } from '@thd-olt-component-react/sponsored-content';

import {
  Typography,
} from '@one-thd/sui-atomic-components';

import {
  extend,
  params,
  shape,
  arrayOf,
  string as stringType,
  number as numberType,
  useDataModel,
  QueryContext,
} from '@thd-nucleus/data-sources';
import { useALPVariables } from '../helpers/useALPVariables';
import { useWindowEffects } from '../helpers/useWindowEffects';
import { TRIES_BEFORE_TIMEOUT } from '../constants';

export const ArticleListingPage = () => {
  declareContexts([ExperienceContext, QueryContext]);

  const { context = '' } = useParams();
  const { pathname = '', search = '' } = useLocation();

  const {
    pageVariables, popState, variables, handlePopState, updateVariables
  } = useALPVariables(pathname);

  const {
    categoryID, topicID, type, filters, page
  } = pageVariables;
  const [mounted, setMounted] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const { data, loading, refetch } = useDataModel('articles', {
    variables: pageVariables,
    onError: (error) => {
      if (retryCount < TRIES_BEFORE_TIMEOUT) {
        setRetryCount((cnt) => cnt + 1);
        refetch();
      }
    },
    onCompleted: () => {
      setRetryCount(0);
    }
  });

  const canonicalURL = data?.articles?.canonicalURL || '';
  const hasNCNI5 = canonicalURL.includes('NCNI-5');
  let queryParams = search && search.replace('?NCNI-5', '');
  if (hasNCNI5 && search && search.indexOf('NCNI-5') < 0) {
    queryParams = search.replace('?', '&');
  }
  const contextValue = context ? '/' + context : '';
  const completePath = contextValue + canonicalURL + queryParams;
  const isNonEndemicBannerEnabled = useConfigService('fs-prop:isNonEndemicBannerEnabled') || false;

  useWindowEffects({
    popState,
    handlePopState,
    canonicalURL,
    variables,
    mounted,
    setMounted,
    completePath,
  });

  if (!data) {
    return null;
  }
  const {
    breadcrumbs = [],
    titleText = '',
    totalArticles,
    totalPages,
    articlesPerPage,
  } = data?.articles || {};

  const pageStart = (page - 1) * articlesPerPage + 1;
  const pageEnd = Math.min(page * articlesPerPage, totalArticles);
  const pageRange = `${pageStart}-${pageEnd}`;
  const isDebug = search.includes('debug');
  const justPath = completePath.substring(completePath.indexOf('?'));

  const queryHandler = (name, pageNum = 1) => {
    const [, , , pageTitle, filtersParam] = canonicalURL.split('/');
    const pageString = pageNum > 1 ? '/' + pageNum : '';
    const value = `${contextValue}/c/alp/${pageTitle}/${filtersParam}${pageString}${search}`;
    return {
      name,
      value,
    };
  };

  const triggerAnalytics = (filterGroups) => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('article-listing-page.ready', {
      breadcrumbs,
      variables: {
        ...pageVariables,
        categoryId: categoryID,
        topicId: topicID,
        infoType: type,
        filterArray: filters,
      },
      filters: filterGroups,
    });
  };

  return (
    <div
      name="article-listing-page-wrapper"
      className="sui-flex sui-flex-col sui-gap-6 sui-bg-primary sui-py-2 sui-px-4 lg:sui-px-8"
    >
      <ErrorBoundary name="redirector" useContextForProps>
        <ALPRedirector variables={pageVariables} path={completePath} />
      </ErrorBoundary>
      <ErrorBoundary name="article-listing-page-metadata">
        <ArticleListingMetadata variables={pageVariables} />
      </ErrorBoundary>

      <div className="sui-mt-3">
        <ErrorBoundary name="breadcrumbs">
          <Breadcrumbs staticCrumbs={breadcrumbs} />
        </ErrorBoundary>
      </div>

      <div>
        <ErrorBoundary name="articles-title">
          <Typography
            variant="h1"
          >
            {titleText}
          </Typography>
        </ErrorBoundary>
      </div>

      {isNonEndemicBannerEnabled
        && (
          <div
            className="sui-mt-2"
          >
            <ErrorBoundary name="articles-non-endemic-banner">
              <SponsoredNonEndemic experienceSlug="alp" />
            </ErrorBoundary>
          </div>
        )}

      <div>
        <ErrorBoundary name="article-listing-topics">
          <ArticleListingTopics categoryID={categoryID} />
        </ErrorBoundary>
      </div>

      <div className="sui-w-full">
        <ArticleListingFilters
          categoryID={categoryID}
          topicID={topicID}
          type={type}
          filters={filters}
          totalArticles={totalArticles}
          updateVariables={updateVariables}
          triggerAnalytics={triggerAnalytics}
        />
      </div>

      <div className="sui-mt-2">
        {totalArticles < 1 ? (
          <div className="alp-no-results">
            <div className="alp-bold-message">
              <p><strong>No results matching your filter selections</strong></p>
            </div>
            <div className="alp-message">
              <p>Try changing or removing some filters to find the right guide</p>
            </div>
          </div>
        ) : null}
        <ArticleTilesContainer variables={pageVariables} />
      </div>

      <div className="sui-flex sui-flex-col">
        {!loading && (
          <>
            <Pagination
              currentPage={page}
              maxNumericBlocks={5}
              showArrows
              totalPages={totalPages}
              href={canonicalURL}
              noQueryParams
              queryHandler={queryHandler}
              nao={false}
              ssr={!isDebug}
              onPageChanged={() => { }}
            />
            <div
              className="sui-flex sui-flex-row sui-justify-center"
            >
              <Typography>
                Viewing
                {' '}
                <span
                  data-testid="range"
                  className="sui-font-bold"
                >
                  {pageRange}
                </span>
                {' '}
                of
                {' '}
                <span
                  data-testid="total"
                  className="sui-font-bold"
                >
                  {totalArticles}
                </span>
                {' '}
                results.
              </Typography>
            </div>
          </>
        )}

        <div
          className="sui-grid sui-grid-cols-1 lg:sui-grid-cols-2 lg:sui-grid-rows-1 sui-gap-3 lg:sui-gap-4 sui-mt-6"
        >
          <DiyBanner />
          <GetItInstalled />
        </div>
      </div>

      <div
        className="sui-flex sui-flex-row sui-mt-3"
      >
        <EmtLinks path={justPath} />
      </div>
    </div>
  );
};

ArticleListingPage.displayName = 'ArticleListingPage';

ArticleListingPage.dataModel = extend(
  {
    articles: params({
      categoryID: stringType().isRequired(),
      topicID: stringType(),
      page: numberType(),
      limit: numberType(),
      type: stringType(),
      filters: arrayOf(stringType()),
    }).shape({
      totalPages: numberType(),
      articlesPerPage: numberType(),
      page: numberType(),
      titleText: stringType(),
      totalArticles: numberType(),
      canonicalURL: stringType(),
      metadata: shape({
        description: stringType(),
        siteName: stringType(),
        title: stringType(),
        type: stringType(),
        canonicalURL: stringType(),
        facebookAdmins: stringType(),
      }),
      breadcrumbs: arrayOf(
        shape({
          dimensionName: stringType(),
          url: stringType(),
          label: stringType(),
        })
      ),
    }),
  },
  ALPRedirector,
  Breadcrumbs,
  ArticleListingTopics,
  ArticleListingFilters,
  ArticleTilesContainer,
  EmtLinks
);
