/* eslint-disable */
import React from "react";
import { Image } from "@thd-olt-component-react/core-ui";

const DiyBanner = () => {
  return (
    <div className="article-results__banner" data-component="DiyBanner">
      <a
        href="https://www.homedepot.com/workshops/"
        title="Free How-To Workshops Learn Live or On Demand"
      >
        <Image
          src="https://assets.thdstatic.com/images/v1/workshops/2025_How_To_Workshop_banner.jpg"
          alt="Free How-To Workshops Learn Live or On Demand"
          title="Free How-To Workshops Learn Live or On Demand"
          height={100}
          width={300}
          className="stretchy"
        />
      </a>
    </div>
  );
};

DiyBanner.displayName = "DiyBanner";

DiyBanner.dataModel = {};

export { DiyBanner };
